/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, ColumnCover, ColumnDiv, Button, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"The Winter Wedding "}>
        <Column className="css-1k4s2zd --full pb--30 pt--50" name={"introduction"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32982/38228cbdc24645479a4c78f850b69957_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32982/38228cbdc24645479a4c78f850b69957_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32982/38228cbdc24645479a4c78f850b69957_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32982/38228cbdc24645479a4c78f850b69957_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32982/38228cbdc24645479a4c78f850b69957_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32982/38228cbdc24645479a4c78f850b69957_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32982/38228cbdc24645479a4c78f850b69957_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32982/38228cbdc24645479a4c78f850b69957_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--shadow4 fs--220" content={"<span style=\"font-weight: bold; color: var(--white);\">✨Вероника &amp; Михаил✨<br></span><br><br><br><br><br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"information"} style={{"backgroundColor":"rgba(222,204,105,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style4 fs--86 w--300 ls--0" style={{"maxWidth":880}} content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255); font-style: italic; font-weight: bold;\">The Wedding</span><br>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"information-2"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--0 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32982/e809365a82a340059f26d9aeda4944b4_e=0x186x1440x1412_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32982/e809365a82a340059f26d9aeda4944b4_e=0x186x1440x1412_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32982/e809365a82a340059f26d9aeda4944b4_e=0x186x1440x1412_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32982/e809365a82a340059f26d9aeda4944b4_e=0x186x1440x1412_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/32982/e809365a82a340059f26d9aeda4944b4_e=0x186x1440x1412_s=1400x_.jpeg 1400w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--154" content={"<span style=\"caret-color: rgb(255, 255, 255); font-weight: bold; color: var(--white);\"><br><br><br><br><br><br><br>24ти Февруари 2024г.&nbsp;</span><br>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32982/f570475fd67c43c8b609c1d4cd6f8aa0_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32982/f570475fd67c43c8b609c1d4cd6f8aa0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32982/f570475fd67c43c8b609c1d4cd6f8aa0_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32982/f570475fd67c43c8b609c1d4cd6f8aa0_s=860x_.jpeg 860w"}>
              </Image>

              <Button className="btn-box" url={"https://maps.app.goo.gl/xrzXAF73pbaEbgE17?g_st=ic"} href={"https://maps.app.goo.gl/xrzXAF73pbaEbgE17?g_st=ic"} content={"<span style=\"text-decoration: underline;\">Натисни тук за локация с навигация:)</span><br>"}>
              </Button>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--43" content={"<span style=\"color: var(--black); background-color: rgb(255, 255, 255);\">PASAREL LAKE CLUB</span><br>"}>
                    </Title>

                    <Title className="title-box fs--128 mt--02 pl--0 pr--0" content={"<br>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--80" name={"program"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"<span style=\"font-style: italic; font-weight: bold; color: var(--black);\">Програма</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-oyfn8q css-42e4bw pb--60 pt--60" name={"sy76xlz57vj"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/32982/3956f6087ce944a69537cd0777fa160e_bri=85__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3956f6087ce944a69537cd0777fa160e_bri=85__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3956f6087ce944a69537cd0777fa160e_bri=85__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3956f6087ce944a69537cd0777fa160e_bri=85__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3956f6087ce944a69537cd0777fa160e_bri=85__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3956f6087ce944a69537cd0777fa160e_bri=85__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3956f6087ce944a69537cd0777fa160e_bri=85__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3956f6087ce944a69537cd0777fa160e_bri=85__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"<span style=\"color: var(--black); font-weight: bold;\"><br><br><br><br>17:30<br>КОКТЕЙЛ<br><br><br>18:00<br>ЦЕРЕМОНИЯ<br><br><br><br>18:20 ПОЗДРАВЛЕНИЯ<br><br>19:30<br>ЯЛИ, ПИЛИ И ЦЯЛА НОЩ СЕ ВЕСЕЛИЛИ<br><br><br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"nssf2ov37a"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--black); font-style: italic; font-weight: bold;\">Кумуват</span><br>"}>
              </Title>

              <Title className="title-box title-box--style7 ff--2 w--500" content={"<span style=\"color: var(--black); font-style: italic;\"><span style=\"caret-color: rgb(0, 0, 0);\">АЛЕКСАНДЪР<br></span>И<br>КЛЕМЕНТИНА<br>МИЛЧЕВИ</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1qnjoas --center pb--60 pt--60" name={"4rchxa4dvra"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/32982/3027061a827243a2b39ac63171fdfc6a_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3027061a827243a2b39ac63171fdfc6a_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3027061a827243a2b39ac63171fdfc6a_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3027061a827243a2b39ac63171fdfc6a_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3027061a827243a2b39ac63171fdfc6a_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3027061a827243a2b39ac63171fdfc6a_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3027061a827243a2b39ac63171fdfc6a_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32982/3027061a827243a2b39ac63171fdfc6a_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"<br><br><br><br><br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"kzfnpro1i8j"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"<span style=\"color: var(--black); font-style: italic; font-weight: bold;\">Потвърди присъствие до средата на януари 💫</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ic0tj8 --center pb--60 pt--60" name={"v652ijyhcfq"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/32982/9dc250ac7bd346c289f4cfa979f2e885_e=28x503x1412x1202__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32982/9dc250ac7bd346c289f4cfa979f2e885_e=28x503x1412x1202__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/32982/9dc250ac7bd346c289f4cfa979f2e885_e=28x503x1412x1202__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32982/9dc250ac7bd346c289f4cfa979f2e885_e=28x503x1412x1202__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/32982/9dc250ac7bd346c289f4cfa979f2e885_e=28x503x1412x1202__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/32982/9dc250ac7bd346c289f4cfa979f2e885_e=28x503x1412x1202__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32982/9dc250ac7bd346c289f4cfa979f2e885_e=28x503x1412x1202__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32982/9dc250ac7bd346c289f4cfa979f2e885_e=28x503x1412x1202__s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"<br><br><br><br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--80" name={"items"} style={{"backgroundColor":"rgba(222,204,105,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"<span style=\"caret-color: rgb(247, 246, 237); font-style: italic; color: var(--white); font-weight: bold;\">Попълни ⤵️</span><br>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"lqmrv9rwos"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"✨<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":654}} content={"<span style=\"color: var(--black); font-weight: bold;\">Искаме да се забавлявате без ораничение, затова ви предлагаме да оставите децата при вашите близки в този ден, ако е възможно:)&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":654}} content={"✨<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <ContactForm className="--shape2 fs--22" style={{"maxWidth":1050}} action={"/contact"} fields={[{"name":"Твоето име","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Име на половинката/ Без половинка","type":"text","required":true,"placeholder":""},{"name":"И един email адрес за потвърждение :)","type":"text","required":true},{"name":"Предпочитания за алкохол?","type":"textarea","required":true},{"name":"Имате ли предпочитания за менюто (или алергии)?","type":"textarea","required":true},{"type":"plaintext","content":"А как ще се приберете след това?"},{"name":"Организиран от нас транспорт в определени часове","type":"checkbox","required":false},{"name":"Сам ще се придвижвам (можете да си поръчате такси или drink & drive)","type":"checkbox"},{"name":"ИЗПРАТИ","type":"submit"}]} layout={"l2"}>
              </ContactForm>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: var(--black);\"><br>🎁<br>Ще се радваме да отпразнуваме заедно до сутринта, а относно подаръците ви молим да избегнете купуването на цветя или вещи, най-подходящият подарък би се побрал в пощенски плик:)<br></span>🎁</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"photogallery"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32982/13d19ea8b7824808a85b49b1967ecb28_s=860x_r=90_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32982/13d19ea8b7824808a85b49b1967ecb28_s=350x_r=90_.jpeg 350w, https://cdn.swbpg.com/t/32982/13d19ea8b7824808a85b49b1967ecb28_s=660x_r=90_.jpeg 660w, https://cdn.swbpg.com/t/32982/13d19ea8b7824808a85b49b1967ecb28_s=860x_r=90_.jpeg 860w, https://cdn.swbpg.com/t/32982/13d19ea8b7824808a85b49b1967ecb28_s=1400x_r=90_.jpeg 1400w, https://cdn.swbpg.com/t/32982/13d19ea8b7824808a85b49b1967ecb28_s=2000x_r=90_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<br>✨<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32982/5ca17613295e4fd3be54525be927739e_e=0x0x1732x2309_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32982/5ca17613295e4fd3be54525be927739e_e=0x0x1732x2309_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/32982/5ca17613295e4fd3be54525be927739e_e=0x0x1732x2309_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/32982/5ca17613295e4fd3be54525be927739e_e=0x0x1732x2309_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/32982/5ca17613295e4fd3be54525be927739e_e=0x0x1732x2309_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<br>✨<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32982/b583b52ff95a4ce599a001de0be5a58a_s=860x_r=90_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32982/b583b52ff95a4ce599a001de0be5a58a_s=350x_r=90_.jpeg 350w, https://cdn.swbpg.com/t/32982/b583b52ff95a4ce599a001de0be5a58a_s=660x_r=90_.jpeg 660w, https://cdn.swbpg.com/t/32982/b583b52ff95a4ce599a001de0be5a58a_s=860x_r=90_.jpeg 860w, https://cdn.swbpg.com/t/32982/b583b52ff95a4ce599a001de0be5a58a_s=1400x_r=90_.jpeg 1400w, https://cdn.swbpg.com/t/32982/b583b52ff95a4ce599a001de0be5a58a_s=2000x_r=90_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"xn6d9l9e3jn"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"✨<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"footer"} style={{"backgroundColor":"rgba(222,204,105,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style=\"caret-color: rgb(247, 246, 237); color: rgb(247, 246, 237); font-weight: 700;\">🥂 Save the date 🥂</span><br>"}>
              </Title>

              <Text className="text-box lh--2 mt--12" style={{"maxWidth":880}} content={"<span style=\"caret-color: rgb(247, 246, 237); font-weight: bold; color: var(--black);\">И само Левски 😂</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}